import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import { Link } from "gatsby"
import Card from 'react-bootstrap/Card'
import Testimonials from "../components/testimonials"

export default ({data}) => (
  <Layout>
  <Jumbotron className="bg-lightblue">
    <div class="container">
      <div style={{maxWidth:"600px",textAlign:"center",margin:"auto"}}>
        <h2>A community in the making!</h2>
        <p className="mt-4">
          Here's what our students have to say about being a part of the Crampete family and learning skills that empower them! A community in the making- sharing skills, experience and knowledge!
        </p>
    </div>
    </div>
  </Jumbotron>
    <div class="container">
      <div class="row">
        <div class="col-md-6 mb-4">
          <Card className="cardShadow p-0">
              <img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/youtube-512.png" className="image-youtube-icon" alt="youtube icon" />
              <Link to="/modal-youtube#S7gZADsf_ys" className="youtubeSpn" state={{ modal: true, width: "300px"}}>
                <img class="image-youtube" src="https://i.ytimg.com/vi/S7gZADsf_ys/maxresdefault.jpg" alt="youtube video" />
                <div class="image-youtube-overlay"></div>
              </Link>
          </Card>
        </div>
        <div class="col-md-6 mb-4">
          <Card className="cardShadow p-0">
              <img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/youtube-512.png" className="image-youtube-icon" alt="youtube icon" />
              <Link to="/modal-youtube#PSmy_qlgTI4" className="youtubeSpn" state={{ modal: true, width: "300px"}}>
                <img class="image-youtube" src="https://i.ytimg.com/vi/PSmy_qlgTI4/maxresdefault.jpg" alt="youtube video" />
                <div class="image-youtube-overlay"></div>
              </Link>
          </Card>
        </div>
        <div class="col-md-6 mb-4">
            <Card className="cardShadow p-0">
                <img src="https://cdn1.iconfinder.com/data/icons/logotypes/32/youtube-512.png" className="image-youtube-icon" alt="youtube icon" />
                <Link to="/modal-youtube#jB1CCqD3sj8" className="youtubeSpn" state={{ modal: true, width: "300px"}}>
                  <img class="image-youtube" src="https://i.ytimg.com/vi/jB1CCqD3sj8/maxresdefault.jpg" alt="youtube video" />
                  <div class="image-youtube-overlay"></div>
                </Link>
            </Card>
        </div>
      </div>
    </div>
    <br />
    <br />
    <Testimonials />
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
